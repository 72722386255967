.login-auth {
  width: 220px;
  border-radius: 10px;
  border: 1.2px solid var(--nav-border);
  border-color: var(--nav-border);
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

.stats-small--1 canvas {
    opacity: 1;
    position: relative;
    
}

.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}


.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1;
  max-width: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: 100%;
  }
}

.rbc-toolbar {
  font-size: 16px !important;
}

.stats-small--1 .stats-small__data {
  font-size: 16px;
}

.main-content-container {
  background-color: var(--background-off-white) !important;
  height: 100%;
}

.main-sidebar .nav .nav-item.nav-inner .nav-link.nav-inner {
  /* border-bottom: 1px solid #E9E9EC; */
  background-color: var(--background-off-white) !important;
  border-bottom: 1px solid var(--nav-border);
  font-family: 'Figtree', Verdana, sans-serif !important;
  font-weight: 600;
  font-size: 13px;
  color: var(--text-dark);
  padding: .9375rem 1.5625rem .9375rem 2.97rem;
}

.main-sidebar .nav--no-borders .nav-item.nav-inner .nav-link.nav-inner {
  border-bottom: 0;
  text-decoration: none;
}

.main-sidebar .nav .nav-item.nav-inner a.active .nav-link.nav-inner, 
.main-sidebar .nav .nav-item.nav-inner.active {
  box-shadow: none;
  background-color: var(--nav-highlight) !important;
  color: var(--text-dark) !important;
  border-left: 6px solid var(--primary);
  text-decoration: none;
}

.main-sidebar .nav .nav-item.nav-inner
.nav-link.nav-inner.nav-inner:hover,
.main-sidebar .nav .nav-item.nav-inner:hover {
  box-shadow: none;
  background-color: var(--background-off-white);
  color: var(--primary);
  border-left: 3px solid var(--nav-highlight);
  text-decoration: none;
}

.main-sidebar .nav .nav-item.nav-inner .nav-link.nav-inner.active i,
.main-sidebar .nav .nav-item.nav-inner .nav-link.nav-inner:hover i,
.main-sidebar .nav .nav-item.active i,
.main-sidebar .nav .nav-item.nav-inner:hover i {
  color: var(--primary);
}

.main-sidebar .nav .nav-item.nav-inner a.active i {
  color: var(--white);
}

.main-sidebar .nav .nav-item.nav-inner a.active .nav-link.nav-inner i {
  color: var(--text-dark) !important;
}

.main-sidebar .nav .nav-item.nav-inner .nav-link.nav-inner i {
  color: var(--text-dark);
}

.invalid-feedback {
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--red);
}

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
  border-color: var(--red);
  box-shadow: 0 5px 11.5px rgba(196, 24, 60, .06);
}

.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1;
  max-width: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: 100%;
  }
}

.react-tabs__tab-list {
  border-bottom: 1px solid var(--nav-border) !important;
}

.react-tabs__tab {
  padding-bottom: 12px !important;
  border-bottom: 4px solid var(--nav-border) !important;
}

.react-tabs__tab--selected {
  border: none !important;
  border-color: var(--primary) !important;
  color: var(--text-dark) !important;
  border-bottom: 4px solid var(--primary) !important;
}

.tab-selected {
  border: none !important;
  border-color: var(--primary) !important;
  color: var(--text-dark) !important;
  border-bottom: 4px solid var(--primary) !important;
}

.form-body-background {
  background: #FEFEFE !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #F3F2F6 !important;
}

.permission-alert {
  background-color: var(--nav-highlight);
  color: white;
}

.confirm-alert {
  background-color: var(--tertiary);
  color: white;
}

label {
  margin-bottom: 0.1rem;
}

.text-dark {
  color: var(--text-dark);
  margin-right: 1rem;
  font-weight: 500;
}

.text-muted {
  color: var(--text-muted);
  margin-right: 1rem;
  font-weight: 400;
}

.text-muted b {
  color: var(--text-muted);
  margin-right: 1rem;
  font-weight: 600;
}

.text-light {
  color: var(--label);
  margin-right: 1rem;
  font-weight: 400;
}

.text-light b {
  color: var(--label);
  margin-right: 1rem;
  font-weight: 600;
}

.code {
  background-color: #F3F2F6 !important;
  border-radius: 2px;
  border: 1px solid var(--light-border);
  color: var(--text-dark);
  margin-bottom: 1rem !important;
  padding: 0.3rem 1rem;
  font-size: 0.75rem;
}

input#customFile2.form-control {
  padding: 4.5px !important;
}

.tags {
  display: inline-block;
}

.tag {
  display: flex;
  padding: 4px 6px;
  background:  var(--background-off-white);
  border: 1px solid  var(--nav-border);
  color: var(--text-muted);
  /* margin-right: 1rem; */
  margin: 0rem 0.8rem 0.4rem 0rem;
}

.tag i {
  margin-right: 1rem;
  color: var(--text);
  cursor: pointer;
  line-height: 1.2rem;
}

.tag span {
  
}

.bg-transparent {
  background: transparent !important;
}

.matrix-row {
  height: 45px;
  width: 100%;
  background-color: var(--background-off-white);
  display: flex;
}

.matrix-col {
  height: 45px;
  width: 20%;
  border: 2px solid var(--white);
  text-align: center;
  line-height: 45px;
}

.matrix-col.bolder{
  font-weight: 500;
}

@media (min-width: 1300px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1280px;
  }
}
