@font-face {
  font-family: 'THICCCBOI';
  src: url('THICCCBOI-Regular.woff2') format('woff2'),
    url('THICCCBOI-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;  
  font-display: block;
}
@font-face {
  font-family: 'THICCCBOI';
  src: url('THICCCBOI-Medium.woff2') format('woff2'),
    url('THICCCBOI-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;  
  font-display: block;
}
@font-face {
  font-family: 'THICCCBOI';
  src: url('THICCCBOI-Bold.woff2') format('woff2'),
    url('THICCCBOI-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;  
  font-display: block;
}
@font-face {
  font-family: 'THICCCBOI';
  src: url('THICCCBOI-ExtraBold.woff2') format('woff2'),
    url('THICCCBOI-ExtraBold.woff2') format('woff');
  font-weight: 700;
  font-style: normal;  
  font-display: block;
}