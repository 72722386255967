:root {
    --blue: #007bff;
    --indigo: #674eec;
    --purple: #8445f7;
    --pink: #ff4169;
    --red: #F24348;
    --orange: #fb7906;
    --yellow: #FFBD51;
    --green: #1B9C85;
    --teal: #1adba2;
    --cyan: #40A1D8;
    --white: #fff;
    --off-white: #F7F7F7;
    --gray: #868e96;
    --gray-dark: #343a40;
    --success: #1B9C85;
    --info: #40A1D8;
    --warning: #FFBD51;
    --danger: #F24348;
    /* --light: #FBFBFB; */
    --dark: #111B1E;
    /* theme colours */
    --primary: #185ADB;
    --secondary: #EBD714;  /* y */
    --tertiary: #961DE2; /* dark green */
    --quaternary: #CE5A67; /* pinkish */
    --quinary: #0a1931; /* dark green */
    --background: #F7F7F7;
    --background-light-gray: #FAFAFA;
    --background-off-white: #EDEDF2;
    /* --background-off-white: #F3F3F3; */
    --text-dark: #0a1931;
    --text: var(--quinary);
    --text-muted: #383940;
    --label: #58596A;
    --nav-border: #DBDBE5;
    --nav-highlight: #ECF4FF;
    --tab-border: #ECECEC;
    /* --light: #EBECED; */
    --light: #F3F2F6;
    --light-border: #E4E4E4;
  }

html {
    height: 100%;
}

body {
    background-color: var(--background);
    font-weight: 400;
    font-size: 14px;
    font-family: 'Figtree', Verdana, sans-serif;
    height: 100%;
    color: var(--quinary);
}

h1, h2, h3, h4 {
    font-family: 'Figtree', Verdana, sans-serif;
    font-weight: 600;
    color: var(--quinary);
}

.card-header h4, .card-header h6 {
    font-family: 'Figtree', Verdana, sans-serif;
   font-weight: 600;
   color: var(--quinary);
}

h4 {
    font-size: 1.3rem;
    line-height: 2rem;
}

h5, h6 {
    font-family: 'Figtree', Verdana, sans-serif;
    font-weight: 600;
    font-size: 1.05rem;
    color: var(--quinary);
}

label {
    font-family: 'Figtree', Verdana, sans-serif !important;
     /* font-family: "THICCCBOI", sans-serif !important; */
    font-weight: 500;
    color: var(--label);
    font-size: 12px;
}

p.header {
    font-weight: 600;
}

span.page-subtitle {
    letter-spacing: normal !important;
    font-size: 1rem !important;
}

table tr td {
    font-size: 14px;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-flex-to-block {
    display: flex !important;
}

@media (max-width: 763px) {
    .d-flex-to-block {
        display: block!important;
    }
}

.bg-off-white {
    background-color: var(--background-off-white)  !important;
}

.bg-nav {
    background-color: #293339!important;
}

.bg-dark-gray {
    background-color: var(--quinary) !important;
}

.bg-black {
    background-color: var(--text-dark);
}

.bg-light {
    background-color: var(--white) !important;
}

.card.bg-gray {
    background-color: var(--background-light-gray) !important;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.main-content .header-navbar, .main-content>.main-navbar {
    box-shadow: none;
    border-bottom: 1.2px solid var(--nav-border);
    background-color: var(--background-off-white);
}

.main-sidebar {
    background-color: var(--white);
    box-shadow: none;
    border-right: 1.2px solid var(--nav-border);
}

.main-sidebar .nav .nav-item .nav-link {
    border-bottom: 1px solid var(--white);
    font-family: 'Figtree', Verdana, sans-serif !important;
    font-weight: 600;
    font-size: 13px;
    color: var(--text-dark);
    padding: .9375rem 1.5625rem;
}

.main-sidebar .nav--no-borders .nav-item .nav-link {
    border-bottom: 0;
    text-decoration: none;
}

.main-sidebar .nav .nav-item a.active .nav-link, 
.main-sidebar .nav .nav-item.active {
    box-shadow: none;
    background-color: var(--nav-highlight);
    color: var(--text);
    border-left: 6px solid var(--primary);
    text-decoration: none;
}

.main-sidebar .nav .nav-item .nav-link:hover, 
.main-sidebar .nav .nav-item:hover {
    box-shadow: none;
    background-color: var(--background-off-white);
    color: var(--text);
    border-left: 3px solid var(--nav-highlight);
    text-decoration: none;
}

.main-sidebar .nav .nav-item .nav-link.active i, 
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item.active i, 
.main-sidebar .nav .nav-item:hover i {
    color: var(--primary);
}

.main-sidebar .nav .nav-item a.active i {
    color: var(--primary);
}

.main-sidebar .nav .nav-item .nav-link i {
    color: var(--text-dark)
}

@media (max-width: 1020px) and (max-height: 840px) {
    .main-sidebar {
        /* max-width: 100px; */
        width: 100vw;
    }
}

.tabs {
    width: 100%;
}

.tab-item {
    float: left !important;
    width: 50% !important;
    font-size: 14px;
    border-top: 1px solid var(--tab-border);
    border-bottom: 1px solid var(--tab-border);
    text-align: center;
    padding: 14px 24px;
    color: var(--text-dark);
    text-transform: uppercase;
    cursor: pointer;
}

.tab-item.active {
    color: var(--white);
    background-color: rgb(129, 142, 163);
}

.bullet {
    margin-right: .875rem;
    margin-top: 6px;
    margin-left: 1.4rem;
    background-color: var(--white);
    height: 8px;
    width: 8px;
    border-radius: 50%;
}

.bullet.active {
    margin-right: .375rem;
    background-color: var(--secondary);
    height: 14px;
    width: 14px;
    border-radius: 50%;
}

.hyperlink {
    font-weight: 500;
    color: var(--primary);
    cursor: pointer;
}

.nav-link a {
    color: var(--text-dark);
}

.main-footer {
    height: 2.2rem;
}

@media (max-width: 480px) {
    .hide-on-mobile {
        display: none;
    }
}

@media (max-width: 1024px) {
    .main-footer {
        height: auto !important;
    }
    .hide-on-tablet {
        display: none;
    }
    .ac-title {
        margin: 1.2rem 0.6rem !important;
    }
}

.main-footer .copyright {
    color: var(--text-muted);
    font-family: 'Figtree', Verdana, sans-serif;
  /* font-family: "THICCCBOI", sans-serif; */
    font-size: 0.85rem;
}

.error__content h2 {
    font-size: 2.75rem;
}

.float-right {
    float: right;
    margin-right: 1rem;
}

.ac-table {
    overflow-y: auto;
    height: 100%;
}

@media (max-width: 960px) {
    .ac-table {
        margin-top: 0;
    }
}

@media (max-width: 550px) {
    .ac-table {
        margin-top: 0;
    }
}

.card {
    border-radius: .1rem;
    box-shadow: none;
    border: 1.2px solid var(--nav-border) !important;
}

.card-small {
    box-shadow: none;
    border: 1.2px solid var(--nav-border);
    border-radius: 4px;
}

.text-muted {
    color: var(--text-muted) !important;
}

.badge {
    font-size: 90% !important;
    border-radius: 0.15rem;
}

.badge i {
    font-size: large;
}

.status-badge {
    font-size: 110% !important;
}

.stats-icon-block {
    background-color: var(--primary);
    width: 50px;
    height: 50px;
    justify-content: center;
    z-index: 1;
    margin: auto;
    border-radius: 8px;
}

.stats-icon-block i {
    color: var(--white);
    font-size: 28px;
    line-height: 42px;
}

.stats-small__value  {
    color: var(--text-dark);
}

.no-border {
    border: none !important;
}

.border-bottom {
    border-bottom: 1px solid var(--nav-border) !important;
}

table {
    color: var(--text-dark) !important;
}

th, .row-header {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.75rem;
    background-color: var(--background-off-white);
}

table tr th {
    min-height: 32px;
    padding: 4px 8px;
}

.table td, .table th {
    padding: 0.4rem;
}

tr:hover, .row-hightlight:hover {
    background-color: var(--background-off-white);
    cursor: pointer;
}

.border-accent {
    border: 1rem solid var(--secondary);
}

.btn {
    font-family: 'Figtree', Verdana, sans-serif !important;
      /* font-family: "THICCCBOI", sans-serif !important; */
    font-weight: 600;
    font-size: 0.75rem;
}

.btn-light {
    color: var(--text-dark) !important;
    border-color: var(--light-border) !important;
    background-color: var(--light) !important;
}

.btn-white {
    color: var(--text-dark) !important;
    border-color: var(--light-border) !important;
    background-color: var(--light) !important;
}

.btn.btn-white {
    color: var(--text-dark) !important;
    border-color: var(--light-border) !important;
    background-color: var(--light) !important;
}

.btn-green {
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}

.btn-yellow {
    background-color: var(--yellow) !important;
    border-color: var(--yellow) !important;
}

.btn-danger {
    background-color: var(--red) !important;
    border-color: var(--red)!important;
}

.btn-accent:hover {
    color: var(--white);
    background-color: var(--secondary);
    border-color: var(--secondary);
    box-shadow: none;
}

.btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-secondary {
    color: var(--white);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.btn-dark {
    color: var(--white);
    background-color: #293339;
    border-color: #293339;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .btn-dark:hover, .btn-dark:active, .btn-dark:focus, .btn-light:hover, .btn-light:active, .btn-light:focus {
    color: var(--white) !important;
    /* background-color: var(--nav-highlight) !important;
    border-color:var(--nav-highlight) !important;495A63 */
    background-color:#495A63 !important;
    border-color:#495A63!important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05) !important;
}

.badge-warning {
    color: var(--white);
    background-color: var(--yellow);
}

.badge-high {
    color: var(--white);
    background-color: var(--orange);
}

.badge-danger {
    color: var(--white);
    background-color: var(--danger);
}

.badge-secondary {
    color: var(--white);
    background-color: var(--secondary);
}

.badge-primary {
    color: var(--white);
    background-color: var(--primary);
}

.badge-info {
    color: var(--white);
    background-color: var(--blue);
}

.badge-success {
    color: var(--white);
    background-color: var(--green);
}

.badge-light {
    background-color: var(--light);
    color: var(--text);
}

.center-block {
    transform: translate3d(0%, 12%, 0);
    padding-bottom: 1.875rem;
    margin: auto;
    max-width: 600px;
}

.center-block.full{
    transform: translate3d(0%, 0%, 0);
    padding-bottom: 1.875rem;
    margin: auto;
    max-width: 600px;
}

.popup-block {
    transform: translate3d(0%, 4%, 0);
    padding-bottom: 1.875rem;
    margin: auto;
    max-width: 600px;
}

.full-page {
    background-color: var(--background-off-white);
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.split-page {
    background-color: var(--background-off-white);
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.popup {
    background: rgba(0, 0, 0, .45);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10000
}

.center-footer {
    display: flex;
    float: right;
    margin-top: 20px;
    padding-right: 40px;
}

.center-footer h6 {
    color: var(--white) !important;
}

.center-block.full .center-footer h6 {
    color: var(--primary) !important;
}

.center-footer img {
    margin-top: -10px;
}

.center-header {
    display: block;
    /* float: right; */
    margin-top: -20px;
    /* padding-bottom: 20px; */
    padding-right: 40px;
}

.center-header h6 {
    color: var(--white) !important;
}

.center-header img {
    margin-bottom: 10px;
}

.dropdown-menu {
    box-shadow: none !important;
    border: 1.2px solid var(--nav-border);
}

.dropdown-item:focus, .dropdown-item:hover {
    color: var(--quinary) !important;
    background-color: var(--background-off-white)  !important;
    border: none;
    outline: none !important
}

p.error {
    color: var(--red) !important;
    font-size: small;
}

.custom-control-label::before {
    margin-top: 2px;
    width: 1.4rem;
    height: 1.4rem;
    border: 1px solid var(--light-border);
}

.custom-control-input:checked~.custom-control-label::before {
    color: var(--white);
    /* border-color: transparent; */
    background-color: var(--green) !important;
    border-color: var(--green) !important;
    box-shadow: none;
}

.custom-checkbox .custom-control-label::after {
    width: 8px;
    height: 16px;
}

.form-group {
    margin-bottom: 0.5rem;
}

.form-control {
    font-size: 0.9rem;
    font-weight: 400;
    border: 1.5px solid var(--nav-border);
}

.form-control::placeholder {
    font-size: 0.85rem;
    color: '#99BAEB';
}

.input-group-lg>.custom-select, .input-group-lg>.form-control:not(textarea) {
    font-size: 13px;
    color: '#99BAEB';
}

.input-group-lg>.custom-select::placeholder, .input-group-lg>.form-control:not(textarea)::placeholder {
    font-size: 13px;
    color: '#99BAEB';
}

.react-datepicker__time-container {
    box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06);
    width: 110px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px;
}